import React from "react"
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material"

import CountryDropdown from "../components/SignInUp/CountryDropdown"

import Layout from "../components/layout"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { navigate } from "gatsby"
import Seo from "../components/seo"
import { mergeBreakpointsInOrder } from "@mui/system"

const { Config } = require("../../config.js")

const paperStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const avatarStyle = {
  margin: "8px",
}

const formStyle = {
  width: "80%", // Fix IE 11 issue.
  marginTop: "24px",
}

const submitStyle = {
  marginTop: "24px",
  marginBottom: "16px",
}

const imageStyle = {
  marginTop: "64px",
}

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uname: "",
      email: "",
      password: "",
      age: "",
      country: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loginError = ""
  }

  handleSubmit(event) {
    for (const ele in this.state) {
      if (this.state[ele] == "") {
        let errorMessage = ""
        switch (ele) {
          case "uname":
            errorMessage += "User Name"
            break
          case "email":
            errorMessage += "Email ID"
            break
          case "password":
            errorMessage += "Password"
            break
          case "age":
            errorMessage += " Age"
            break
          case "country":
            errorMessage += "Country"
            break
          default:
            errorMessage += ele
        }
        errorMessage += " cannot be empty"
        this.loginError = errorMessage
        navigate("/sign-up/")
        return
      }
    }

    const stringify = require("json-stringify-safe")
    var data = stringify(this.state)

    fetch(Config.route + "/api/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then(res => {
        if (res.ok) {
          navigate("/email-verification/")
        } else {
          res.json().then(res => {
            this.loginError = res.error
            navigate("/sign-up/")
          })
        }
      })
      .catch(err => console.log("Request Failed", err))
  }

  handleChange(event, country) {
    switch (event.target.name) {
      case "uname":
        this.setState({ uname: event.target.value })
        break
      case "email":
        this.setState({ email: event.target.value })
        break
      case "password":
        this.setState({ password: event.target.value })
        break
      case "age":
        this.setState({ age: event.target.value })
        break
      case "country":
        this.setState({ country: country })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <Seo title="Sign Up" />
        <Layout>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CssBaseline />
                <div style={paperStyle}>
                  <Avatar sx={avatarStyle}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign up
                  </Typography>
                  <form style={formStyle}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="uname"
                          name="uname"
                          variant="outlined"
                          required
                          fullWidth
                          id="userName"
                          label="User Name"
                          autoFocus
                          onChange={this.handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="age"
                          label="Age"
                          type="age"
                          id="age"
                          autoComplete="age"
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CountryDropdown
                          onChange={(event, country) => {
                            event.target.name = "country"
                            this.handleChange(event, country.label)
                          }}
                          // onChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={submitStyle}
                      onClick={this.handleSubmit}
                    >
                      Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Link href="/sign-in/" variant="body2">
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                    {this.loginError != "" ? (
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Alert severity="error" sx={submitStyle}>
                            <AlertTitle>Error</AlertTitle>
                            {this.loginError}
                          </Alert>
                        </Grid>
                      </Grid>
                    ) : null}
                  </form>
                </div>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <div style={imageStyle}>
                    <img
                      src={require("../images/Unlock.svg").default}
                      width="100%"
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </>
    )
  }
}

export default SignUp
